<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2>Events | Gallery</h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li>Gallery</li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8">
                <!-- Portfolio Search Box & Add Button-->
                <div>
                  <b-row>
                    <b-col sm="8"><b-form-input v-model="profsearch" placeholder="Search by event name"></b-form-input></b-col>
                      <b-col sm="4" >
                      <p class="tag-rec-cnt" v-if="TotRows>50"> Showing 50 of {{TotRows}}</p>
                      <p class="tag-rec-cnt" v-if="TotRows<50"> Showing {{TotRows}} records</p>                      
                    </b-col>
                  </b-row>    
                </div>
                <!-- Portfolio card Listing goes here -->
                <div id="ThiraCard" v-for="prof in AdmSearchList" v-bind:key="prof.id">
                  <b-row class="row no-gutters profile-card" >
                    <div class="col-md-4 Thira profile-card-5">
                      <div class="card">
                        <div class="card-img-block">
                          <router-link :to="{name:'events-gallery-detail', params:{ EgallId:prof.egall_id}}"><img class="card-img-top" v-bind:src="prof.egall_logo" alt="Image"></router-link>
                        </div>
                      </div>
                    </div>
                      <div class="col-md-8 Thira profile-card-5">
                        <div class="card-body event-desc-block">
                          <h5 class="card-title"><router-link :to="{name:'events-gallery-detail', params:{ EgallId:prof.egall_id}}"><h5>{{prof.egall_tittle}}</h5></router-link></h5>
                          <!-- <p v-if="prof.egall_desc.length<100"><span v-html="prof.egall_desc"></span>
                          <p v-else><span v-html="prof.egall_desc.substring(0,270)"></span></p> -->
                          <p class="card-text pre-formatted desc" v-if="prof.egall_desc.length<50"><span v-html="prof.egall_desc"></span>
                          <p v-else><span v-html="prof.egall_desc.substring(0,300)"></span></p>
                          <div class="read-more">
                            <router-link :to="{name:'events-gallery-detail', params:{ EgallId:prof.egall_id}}">View Gallery</router-link>
                          </div>  
                        </div> 
                      </div>
                               
                  </b-row>    
                </div>
              <b-pagination pills class="mt-5"
                v-model="currentPage"
                :total-rows="TotRows"
                :per-page="PerPage"
                aria-controls="ThiraCard"
                @change="handlePageChange"
                align="center"
                size="lg"
              ></b-pagination>                  
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <!-- sidebar search box -->
                <!-- <h3 class="sidebar-title">Search</h3>
                <div class="sidebar-item search-form">
                  <form action="">
                    <input type="text">
                    <button type="submit"><i class="icofont-search"></i></button>
                  </form>
                </div> -->
                <!-- sidebar Categories -->
                <!-- <h3 class="sidebar-title">Categories</h3>
                <div class="sidebar-item categories">
                  <ul>
                    <li><a href="#">Dance <span>(25)</span></a></li>
                    <li><a href="#">Music <span>(12)</span></a></li>
                    <li><a href="#">Drama <span>(5)</span></a></li>
                    <li><a href="#">Folk Arts <span>(22)</span></a></li>
                    <li><a href="#">Instruments <span>(8)</span></a></li>
                    <li><a href="#">Kshetra Vadyam <span>(14)</span></a></li>
                    <li><a href="#">Performing Arts <span>(14)</span></a></li>
                    <li><a href="#">Ritual Arts <span>(14)</span></a></li>
                  </ul>
                </div>  -->
                <!-- sidebar recent posts-->
                <SideRecentPerformers/>
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/>  
              </div>

            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { CardPlugin } from 'bootstrap-vue'

Vue.use(CardPlugin)
Vue.use(Vueaxios,axios)

  export default {
  name:"EventsPhotoGallery",
  metaInfo: {
    title: "Thiraseela :: ",
    titleTemplate: "%s Performing Arts Events Photo Galery | Artists Portfolios | Book Stage Shows | Online Program Booking"
  },      
    data() {
      return {
        profsearch: "",
        items: [],
        gallery: [],
        // Pagination Variables
        CurrPage: 1,
        MaxPage: 0,
        TotRows: 0,
        PerPage: 50,
        currentPage: 1,
        More50Rec: false,           
      }
    },
    mounted()
    {
      this.ReadEventsGallery()
      this.GetTotRecCnt()
    },
    methods: {
      ReadEventsGallery(){
        const REQformData1 = new FormData();
        REQformData1.append('Page_No', this.CurrPage);        
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadEventsGallery',REQformData1)
        .then((resp)=>{
        // this.gallery=resp.data.EventsGalleryMain;
        resp.data.EventsGalleryMain.forEach(element => this.gallery.push(element))
      })
      },
      
      GetTotRecCnt(){
        const REQformData = new FormData();
        REQformData.append('table_name', 'events_gallery_main');          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetTotRecCnt',REQformData)
        .then((resp)=>{
        const FirstObject = resp.data.GetTotRecCnt[0];
        this.TotRows = FirstObject.Tot_Rec_Cnt;
        // console.log('TotRows',this.TotRows)
      })
      },
      handlePageChange(value) {
      this.CurrPage = value;
      document.body.scrollIntoView();
      // console.log('this.CurrPage',this.CurrPage)
      if (this.CurrPage > this.MaxPage) 
        {
          this.ReadEventsGallery();
          this.MaxPage = this.CurrPage
        }
      // console.log('this.MaxPage',this.MaxPage)
      },             
    },
    computed: {
    RespSearchList: function(){
        return this.gallery.filter((gallery) => {
        return gallery.egall_tittle.toLowerCase().match(this.profsearch.toLowerCase())
      });
    },
      AdmSearchList: function()
        {
          return this.RespSearchList.slice(
          (this.currentPage - 1) * this.PerPage,
          this.currentPage * this.PerPage,
          );
        }        
  }
  }
</script>

<style scoped>
.profile-card {
    margin-top:35px;
    box-shadow:0 0 10px rgba(0,0,0,0.63);
    border-radius:5px;
}
.profile-card-5{
    /* box-shadow:0 0 10px rgba(0,0,0,0.63); */
    border-radius:5px;
    height: 200px;
    /* height: 450px; */
    /* height: 475px; */
    /* overflow: hidden;     */
}
.profile-card-5 .card-img-block {
    width: 91%;
    margin: 0 auto;
    position: relative;
    top: -20px;
}
.profile-card-5 .event-desc-block {
  /* border-radius:5px; */
  height: 200px;
  overflow: hidden;

}
.profile-card-5 .card-img-block img{
    border-radius:10px;
    height: 200px;
    overflow: hidden;
}
.profile-card-5 h5{
    color:#C2185B;
    font-weight:800;
    font-size:16px;
}
.profile-card-5 p{
    font-size:14px;
    font-weight:300;
}
.pre-formatted {
  white-space: pre-line;
  text-align: justify;
  /* max-height: 450px;
  overflow: auto; */
  display: inline-block;
}
</style>
